import React from 'react'
import Helmet from 'react-helmet'

import Navbar from './Navbar'
import './all.sass'

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet>
      <title>Clementina.coop</title>
      <meta name="description" content="Programando un mundo mejor" />
      <meta property="og:title" content="Clementina" />
      <meta property="og:type" content="article" />
      <meta property="og:url" content="https://clementina.coop/" />
      <meta property="og:image" content="https://clementina.coop/img/logo-fondo.jpg" />
      <meta property="og:description" content="Programando un mundo mejor" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@clementinacoop" />
      <meta name="twitter:title" content="Clementina" />
      <meta name="twitter:description" content="Programando un mundo mejor" />
      <meta name="twitter:creator" content="@clementinacoop" />
      <meta name="twitter:image:src" content="https://clementina.coop/img/logo-fondo.jpg" />
    </Helmet>
    <Navbar />
    <div>{children}</div>
  </div>
)

export default TemplateWrapper
