import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link, graphql } from 'gatsby'

export default class IndexPage extends React.Component {
  render() {
    const { posts, title } = this.props

    return (
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h1 className="has-text-weight-bold is-size-2">{title}</h1>
              {posts.map(({ node: post }) => (

                <div  key={post.id}>
                  <h1 id={post.slug}><small>{moment(post.date).format("DD.MM.YYYY")}</small>
                      <Link className="has-text-primary" to={post.slug}>
                        <span dangerouslySetInnerHTML={{ __html: post.title }} />
                      </Link>
                  </h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.excerpt.replace(/<a class="link-more.*/, ''),
                    }}
                  />
                  <Link className="button is-small" to={post.slug}>
                    Leer más →
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    author {
      name
      slug
      avatar_urls {
        wordpress_48
      }
    }
    date(formatString: "MMMM DD, YYYY")
    slug
  }
`
