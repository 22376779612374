import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import logo from '../img/logo-chico.png'

const Navbar = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(sort: { fields: wordpress_id }, limit: 5) {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <header>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <nav className="navbar is-transparent">
                <div className="navbar-brand">
                  <Link to="/">
                    <figure className="image">
                      <img src={logo} alt="Clementina.coop" />
                    </figure>
                  </Link>
                </div>
                <div className="navbar-end">
                  {data.allWordpressPage.edges.map(edge => (
                    <Link
                      className="navbar-item"
                      to={edge.node.slug}
                      key={edge.node.slug}
                    >
                      {edge.node.title}
                    </Link>
                  ))}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    )}
  />
)

export default Navbar
